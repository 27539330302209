@.myProjectDetailModuleHandler = ->
    $(document).ready(->
        myProjectDetailsEventHandler()
    )
    myProjectDetailsEventHandler = ->
        $('#input-project-name').on('foucusin', ->
            $(this).data('project-name', $(this).val())
        )
        $('#input-project-name').on('focusout', ->
            newname = $(this).val()
            oldname = $(this).data('name')
            if (newname.length == 0)
                $(this).val($(this).data('name'))
            else if (oldname != newname)
                $.ajax({
                    type: "POST"
                    url: window.location
                    data: {
                        "projectname": newname
                    }
                    success: (data) =>
                        $('#input-project-name').data('name', newname)
                    error: (data) =>
                        title = $('#input-project-name')
                        title.val(title.data('project-name'))

                    headers: {
                        "X-CSRFToken": getCookie("csrftoken")
                    }
                })
        )

        $('#input-project-name').hover(
            -> $(this).focus().val($(this).val()),
            -> $(this).blur(),
        )

        # Switch btn in mySpec - move
        $('.jsMySpecMoveDesignBtn').on('click', ->
            designName = $(this).data('design-name')
            designId = $(this).data('design-id')
            modal = $('.jsMySpecModalMoveDesign')
            modal.find('.jsMySpecModalDesignName').text(designName)
            modal.find('.jsMySpecModalDesignId').val(designId)
            modal.addClass('active')
        )

        # Switch btn in mySpec - share
        $('.jsMySpecShareDesignBtn').on('click', ->
            elem = $(this)
            designId = elem.data('design-id')
            shareUrl = elem.data('share-url')
            modal = $(document).find('.jsMySpecModalShareDesign')
            shareInput = modal.find('.jsMySpecShareLink')
            urlPrefix = shareInput.data('link-prefix')
            modal.find('.jsMySpecCopyClipboard').text(gettext('Copy to clipboard'))
            if not shareUrl
                $.ajax({
                    type: "POST"
                    url: '/myspacestor/share-design/'
                    data: {
                        "designId": designId,
                    }
                    success: (data) =>
                        shareInput.val(urlPrefix + data['share_link'])
                        modal.addClass('active')
                    error: (data) =>
                    error: (data) =>
                        console.log('Error ' + data)

                    headers: {
                        "X-CSRFToken": getCookie("csrftoken")
                    }
                })
            else
                shareInput.val(urlPrefix + shareUrl)
                modal.addClass('active')
        )

        # share modal - btn
        $('.jsMySpecShareLink').on('click', ->
            $(this).select()
        )
        $('.jsMySpecCopyClipboard').on('click', (e)->
            e.preventDefault()
            $(this).parents('.jsModalInfoContent').find('.jsMySpecShareLink').select()
            document.execCommand('copy')
            $(this).text(gettext('Copied!'))
        )

        # select in move modal
        $('.jsMySpecMoveProjSelect select').select2({
            minimumResultsForSearch: Infinity
            width: '100%'
            language: window.current_language || 'en'
        })

        # move modal - btn
        $('.jsMySpecModalMoveSubmit').on('click', ->
            designId = $('.jsMySpecModalMoveDesign').find('.jsMySpecModalDesignId').val()
            targetProjectId = $(".jsMySpecModalProjectId option:selected").val()
            if targetProjectId
                $.ajax({
                    type: "POST"
                    url: '/myspacestor/move-design/'
                    data: {
                        "designId": designId,
                        "projectId": targetProjectId,
                    }
                    success: (data) =>
                        location.href = '/myspacestor/project/' + targetProjectId + '/'
                    error: (data) =>
                        console.log('Error ' + data)

                    headers: {
                        "X-CSRFToken": getCookie("csrftoken")
                    }
                })
        )

        $('.jsMySpecProjectRemove').on('click', ->
            box = $(this).parent('.jsMySpecBox')
            designId = box.data('design-id')
            $.ajax({
                type: "POST"
                url: '/myspacestor/remove-design/'
                data: {
                    "designId": designId,
                }
                success: (data) =>
                    box.remove()
                error: (data) =>
                    console.log('Error ' + data)

                headers: {
                    "X-CSRFToken": getCookie("csrftoken")
                }
            })
        )
